//@mixin xxl {
//  @media (min-width: 1721px) {
//    @content;
//  }
//}

@mixin xl {
  @media (max-width: 1500px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1366px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin x {
  @media (max-width: 450px) {
    @content;
  }
}

@mixin z {
  @media (max-width: 380px) {
    @content;
  }
}

.container {
  width: 1180px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #8B8B8B;
  border-right: 1px solid #8B8B8B;
  box-sizing: content-box;

  @include xl {
    max-width: calc(100% - 60px);
  }
  @include lg {
    max-width: calc(100% - 60px);
  }
  @include md {
    max-width: calc(100% - 40px);
  }
  @include sm {
    max-width: calc(100% - 40px);
  }
  @include xs {
    max-width: calc(100% - 40px);
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  @include xl {
    margin-left: auto;
    margin-right: auto;
  }
}

.sector {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
