.section__product {
  .container {
    min-height: 80vh;
  }
}

.section__product-block {
  padding-top: 48px;
  padding-left: 100px;
  padding-right: 100px;
  @include lg {
    padding-left: 50px;
    padding-right: 50px;
  }
  @include md {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
}

.s__product-img {
  margin-bottom: 75px;
  @include sm {
    margin-bottom: 36px;
  }

  img {
    width: 100%;
  }
}

.s__product-title {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 24px;
  @include sm {
    justify-content: center;
  }

  span {
    color: #393939;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    &:first-child {
      font-size: 72px;
      margin-right: 16px;
      @include md {
        font-size: 50px;
      }
      @include xs {
        font-size: 36px;
      }
    }

    &:last-child {
      font-size: 48px;
      @include md {
        font-size: 36px;
      }
      @include xs {
        font-size: 24px;
      }
    }
  }
}

.s__product-info {
  display: flex;
  @include sm {
    flex-wrap: wrap;
  }
}

.s__product-description {
  margin-right: 20px;
  flex: 0 0 calc(100% - 300px);
  @include md {
    flex: 0 1 auto;
    margin-right: 0;
  }

  p {
    font-family: "Montserrat", sans-serif;
    color: #393939;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    @include sm {
      text-align: center;
      margin-bottom: 24px;
    }
  }
}

.s__product-images__block {
  flex: 0 0 280px;
  width: 280px;
  @include md {
    margin-left: 20px;
    flex: 1 0 280px;
  }
  @include sm {
    margin-left: auto;
    margin-right: auto;
    flex: 0 0 280px;
    text-align: center;
    order: -1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 24px;
  }
  @include x {
    flex: 1 1 auto;
  }
}

.s__product-image__block {

}

.s__product-image {
  display: none;

  img {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  &.active {
    display: block;
  }
}

.s__product-image__navs {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.s__product-image__nav {
  color: #C7C7C7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    color: rgba(57, 57, 57, 0.75);
  }

  &.active {
    color: #393939;
  }
}

.productSwiper {
  width: 680px;
  max-width: 100%;
  height: 100%;
  margin-left: 0;
  margin-bottom: 48px;

  .swiper-pagination {
    position: relative;
    display: flex;
    bottom: 0;
    justify-content: center;
    z-index: 50;
    max-width: 80%;
    margin: 16px auto;
  }

  .swiper-pagination-bullet {
    width: 100%;
    flex-shrink: 10;
    border-radius: 0;
    height: 4px;
    position: relative;
    overflow: hidden;
    background: #ECCC61;
    opacity: 1;
    max-width: 40px;
  }

  .swiper-pagination-bullet-active {
    background: #C46C29;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sl__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .sl__info-col1 {
    flex: 0 0 50px;
    margin-right: 20px;

    img {
      //width: 300px;
    }
  }

  .sl__info-col2 {
    flex: 1 0 auto;
    width: calc(100% - 70px);
  }

  .sl__info-text {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 6px;
  }

  .sl__info-desc {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

  }
}

