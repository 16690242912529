.container {
  width: 1180px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #8B8B8B;
  border-right: 1px solid #8B8B8B;
  box-sizing: content-box;
}
@media (max-width: 1500px) {
  .container {
    max-width: calc(100% - 60px);
  }
}
@media (max-width: 1366px) {
  .container {
    max-width: calc(100% - 60px);
  }
}
@media (max-width: 1024px) {
  .container {
    max-width: calc(100% - 40px);
  }
}
@media (max-width: 768px) {
  .container {
    max-width: calc(100% - 40px);
  }
}
@media (max-width: 576px) {
  .container {
    max-width: calc(100% - 40px);
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1500px) {
  .row {
    margin-left: auto;
    margin-right: auto;
  }
}

.sector {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

html, body, html a {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  color: #000000;
}

body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background: linear-gradient(180deg, #EBE9E7 0%, #FFE8C8 100%);
}
body main {
  flex-grow: 2;
}

body * {
  outline: rgba(0, 0, 0, 0) none;
  font-family: "Montserrat Alternates", sans-serif;
}

body.menu__open, html.menu__open {
  overflow-y: hidden;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(49, 51, 57);
  border-radius: 0px;
  background-color: #FFF;
}

body::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  background-color: #FFF;
  border-radius: 0px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgb(49, 51, 57);
  background-color: rgb(49, 51, 57);
}

.nav__html ol {
  display: flex;
  flex-wrap: wrap;
}
.nav__html ol li {
  margin-right: 40px;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 182px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: #393939;
}
h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 124px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: #393939;
  line-height: 1;
}
@media (max-width: 1366px) {
  h2 {
    font-size: calc(124px - 2vmax);
  }
}
@media (max-width: 1024px) {
  h2 {
    font-size: calc(124px - 3vmax);
  }
}
@media (max-width: 768px) {
  h2 {
    font-size: 72px;
    text-align: center;
  }
}
@media (max-width: 380px) {
  h2 {
    font-size: 60px;
  }
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: #393939;
}

h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #393939;
}

a {
  display: inline-block;
  text-decoration: none;
}

.header__menu-burger {
  position: absolute;
  right: 12px;
  display: none;
}
@media (max-width: 1024px) {
  .header__menu-burger {
    display: block;
  }
}

.menu__btn:hover span {
  background: #000000;
}

.menu__btn span {
  display: block;
  height: 2px;
  background: #000000;
  margin: 8px 0;
}

.menu__btn span:nth-child(even) {
  width: 28px;
  margin-left: 18px;
  transition: all 0.3s;
}

.menu__btn span:nth-child(odd) {
  position: relative;
  right: 0;
  width: 46px;
  transition: all 0.3s;
}

.menu__btn.active span:nth-child(even) {
  width: 0;
  margin-left: 48px;
}

.menu__btn.active span:first-child {
  left: 6px;
  top: 14px;
  transform: rotateZ(45deg);
}

.menu__btn.active span:nth-child(3) {
  left: 6px;
  top: -6px;
  transform: rotateZ(-45deg);
}

.get__header-mobile__menu {
  position: absolute;
  width: 100%;
  height: calc(100vh - 121px);
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #EBE9E7 0%, #FFE8C8 100%);
  top: 121px;
  z-index: -100;
  opacity: 0;
}
@media (max-width: 768px) {
  .get__header-mobile__menu {
    top: 81px;
  }
}
.get__header-mobile__menu.active {
  z-index: 100;
  opacity: 1;
}
.get__header-mobile__menu ul {
  list-style: none;
  padding: 10px;
  margin-top: 36px;
}
.get__header-mobile__menu ul li {
  margin-bottom: 18px;
}
.get__header-mobile__menu ul li a {
  color: #393939;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 1;
}

.get__header-block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid #8B8B8B;
}
@media (max-width: 450px) {
  .get__header-block {
    justify-content: flex-start;
    padding-left: 20px;
  }
}

.get__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
}
@media (max-width: 1280px) {
  .get__header {
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .header__menu-left {
    display: none;
  }
}
.header__menu-left ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.header__menu-left ul li a {
  padding-left: 36px;
  padding-right: 36px;
}
@media (max-width: 1100px) {
  .header__menu-left ul li a {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media (max-width: 1024px) {
  .header__menu-right {
    display: none;
  }
}
.header__menu-right ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}
.header__menu-right ul li a {
  padding-left: 36px;
  padding-right: 36px;
}
@media (max-width: 1100px) {
  .header__menu-right ul li a {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.header__menu-lang {
  width: 78px;
}
@media (max-width: 1024px) {
  .header__menu-lang {
    display: none;
  }
}
.header__menu-lang ul {
  list-style: none;
  padding: 0;
  display: flex;
}
.header__menu-lang ul li {
  margin-left: 10px;
}
.header__menu-lang ul li a {
  display: flex;
}
.header__menu-lang ul li a img {
  width: 16px;
  height: 11px;
}

.header__menu-logo a {
  color: #393939;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 1;
  padding: 11px 36px 29px;
}
@media (max-width: 768px) {
  .header__menu-logo a {
    padding: 0;
  }
}

.main__hero-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.main__hero-block__col {
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
}
@media (max-width: 1024px) {
  .main__hero-block__col {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-top: 36px;
  }
}

@media (max-width: 1024px) {
  .main__hero-block__img {
    display: flex;
    justify-content: center;
  }
}

.main__hero-block__text {
  margin-top: 16px;
  column-count: 2;
}
@media (max-width: 1366px) {
  .main__hero-block__text {
    column-count: 1;
  }
}
@media (max-width: 1024px) {
  .main__hero-block__text {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.main__hero-block__text p {
  margin-bottom: 16px;
  break-inside: avoid;
}
@media (max-width: 1024px) {
  .main__hero-block__text p {
    text-align: center;
  }
}

.main__posts-items {
  display: flex;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap;
  padding-top: 36px;
}
@media (max-width: 576px) {
  .main__posts-items {
    margin-left: auto;
    margin-right: auto;
  }
}

.main__posts-item {
  margin-left: 10px;
  margin-right: 10px;
  flex: 0 0 calc(33.3333333333% - 20px);
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .main__posts-item {
    flex: 0 0 calc(50% - 20px);
    margin-bottom: 40px;
  }
}
@media (max-width: 576px) {
  .main__posts-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.main__posts-item__link {
  display: flex;
  flex-direction: column;
}
.main__posts-item__link:hover .main__posts-item__img img {
  transform: scale(1.05);
}

.main__posts-item__img {
  overflow: hidden;
}
.main__posts-item__img img {
  transition: 0.3s ease-in-out;
}
@media (max-width: 576px) {
  .main__posts-item__img img {
    width: 100%;
  }
}

.main__posts-item__title {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
}

.main__posts-item__date {
  color: #000;
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}

.section__post-item {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 60px;
  padding-top: 20px;
}
@media (max-width: 1024px) {
  .section__post-item {
    padding-left: 0;
    padding-right: 0;
  }
}

.section__post-item__date {
  font-size: 12px;
  margin-bottom: 10px;
}

.section__post-item__content {
  margin-top: 24px;
}
.section__post-item__content p, .section__post-item__content span, .section__post-item__content a {
  font-size: 14px;
}

.section__post-item__text {
  column-count: 2;
  margin-top: 24px;
}
@media (max-width: 1024px) {
  .section__post-item__text {
    column-count: 1;
  }
}
.section__post-item__text p {
  margin-bottom: 24px;
  font-size: 14px;
  break-inside: avoid;
}
@media (max-width: 1024px) {
  .section__post-item__text p {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 12px;
  }
}

.section__post-item__content-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
}

.section__post-item__image {
  flex: 0 0 calc(50% - 10px);
  max-width: calc(50% - 10px);
  margin-bottom: 24px;
}
@media (max-width: 1024px) {
  .section__post-item__image {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .section__post-item__image img {
    width: 100%;
  }
}
.section__post-item__description {
  flex: 0 0 calc(50% - 10px);
  width: calc(50% - 10px);
}
@media (max-width: 1024px) {
  .section__post-item__description {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.section__post-item__description p {
  margin-bottom: 24px;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .section__post-item__description p {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 12px;
  }
}

.section__shop .container {
  padding-bottom: 32px;
  min-height: calc(100vh - 100px);
}
@media (max-width: 768px) {
  .section__shop .container {
    min-height: calc(100vh - 81px - 105px);
  }
}

.section__shop_block {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 30px;
}
@media (max-width: 1024px) {
  .section__shop_block {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 450px) {
  .section__shop_block {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.section__shop_block:last-child .section__shop-items {
  border-bottom: unset;
}

.section__shop-title__link:hover img:first-child {
  display: none;
}
.section__shop-title__link:hover img:last-child {
  display: block;
}
.section__shop-title__link img:first-child {
  display: block;
}
.section__shop-title__link img:last-child {
  display: none;
}

.section__shop-items__block {
  display: block;
}
@media (max-width: 1024px) {
  .section__shop-items__block {
    display: none;
  }
}

.catSwiper {
  display: none;
}
@media (max-width: 1024px) {
  .catSwiper {
    display: block;
  }
}
.catSwiper .swiper-slide-prev,
.catSwiper .swiper-slide-next {
  transform: scale(0.8);
  opacity: 0.7;
}

.section__shop-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #393939;
  padding-bottom: 30px;
}
@media (max-width: 1024px) {
  .section__shop-items {
    flex: unset;
    justify-content: unset;
    flex-wrap: unset;
    border-bottom: unset;
  }
}

.section__shop-item {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 16px;
  width: unset;
  text-align: center;
}
@media (max-width: 1024px) {
  .section__shop-item {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.section__shop-item__link {
  text-align: center;
}
.section__shop-item__link img {
  margin-left: auto;
  margin-right: auto;
}

.section__404 {
  height: 100vh;
}

.section__404-block {
  height: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.section__404-block h1 {
  position: relative;
  display: inline-flex;
}
@media (max-width: 1366px) {
  .section__404-block h1 {
    font-size: 100px;
  }
}
.section__404-block h1:after {
  position: absolute;
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='87' height='86' viewBox='0 0 87 86' fill='none'%3E%3Cpath d='M29.648 33.8373C23.0375 32.6176 16.6954 36.9838 15.4756 43.5943C14.2558 50.2048 18.622 56.5469 25.2325 57.7667C31.843 58.9865 38.1852 54.6203 39.4049 48.0098C40.6247 41.3993 36.2585 35.0571 29.648 33.8373ZM26.3364 51.7844C23.0461 51.1772 20.8508 47.9884 21.4579 44.6982C22.0651 41.4079 25.2538 39.2126 28.5441 39.8197C31.8344 40.4268 34.0297 43.6156 33.4226 46.9059C32.8155 50.1962 29.6267 52.3915 26.3364 51.7844ZM50.8476 53.2142C45.9122 52.3035 41.129 55.5965 40.2183 60.5319C39.3076 65.4673 42.6006 70.2505 47.536 71.1612C52.4714 72.0719 57.2546 68.779 58.1653 63.8435C59.076 58.9081 55.783 54.1249 50.8476 53.2142ZM48.6399 65.1789C46.9947 64.8753 45.8971 63.2809 46.2006 61.6358C46.5042 59.9906 48.0986 58.893 49.7437 59.1965C51.3889 59.5001 52.4865 61.0945 52.183 62.7397C51.8794 64.3848 50.285 65.4825 48.6399 65.1789ZM61.9277 18.1425C52.8644 16.4702 44.113 22.4951 42.4406 31.5583C40.7682 40.6216 46.7931 49.3731 55.8564 51.0454C64.9196 52.7178 73.6711 46.6929 75.3435 37.6296C77.0158 28.5664 70.9909 19.8149 61.9277 18.1425ZM56.9603 45.0631C51.1873 43.9978 47.3577 38.4352 48.4229 32.6622C49.4882 26.8892 55.0509 23.0596 60.8238 24.1249C66.5968 25.1901 70.4264 30.7528 69.3611 36.5258C68.2959 42.2987 62.7332 46.1283 56.9603 45.0631Z' fill='%23393939'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  width: 87px;
  height: 86px;
  top: -40px;
  right: 70px;
  transform: rotate(10.455deg);
}
@media (max-width: 1366px) {
  .section__404-block h1:after {
    width: 50%;
    height: 50%;
    right: 0;
  }
}
.section__404-block h1:before {
  position: absolute;
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='87' height='86' viewBox='0 0 87 86' fill='none'%3E%3Cpath d='M29.648 33.8373C23.0375 32.6176 16.6954 36.9838 15.4756 43.5943C14.2558 50.2048 18.622 56.5469 25.2325 57.7667C31.843 58.9865 38.1852 54.6203 39.4049 48.0098C40.6247 41.3993 36.2585 35.0571 29.648 33.8373ZM26.3364 51.7844C23.0461 51.1772 20.8508 47.9884 21.4579 44.6982C22.0651 41.4079 25.2538 39.2126 28.5441 39.8197C31.8344 40.4268 34.0297 43.6156 33.4226 46.9059C32.8155 50.1962 29.6267 52.3915 26.3364 51.7844ZM50.8476 53.2142C45.9122 52.3035 41.129 55.5965 40.2183 60.5319C39.3076 65.4673 42.6006 70.2505 47.536 71.1612C52.4714 72.0719 57.2546 68.779 58.1653 63.8435C59.076 58.9081 55.783 54.1249 50.8476 53.2142ZM48.6399 65.1789C46.9947 64.8753 45.8971 63.2809 46.2006 61.6358C46.5042 59.9906 48.0986 58.893 49.7437 59.1965C51.3889 59.5001 52.4865 61.0945 52.183 62.7397C51.8794 64.3848 50.285 65.4825 48.6399 65.1789ZM61.9277 18.1425C52.8644 16.4702 44.113 22.4951 42.4406 31.5583C40.7682 40.6216 46.7931 49.3731 55.8564 51.0454C64.9196 52.7178 73.6711 46.6929 75.3435 37.6296C77.0158 28.5664 70.9909 19.8149 61.9277 18.1425ZM56.9603 45.0631C51.1873 43.9978 47.3577 38.4352 48.4229 32.6622C49.4882 26.8892 55.0509 23.0596 60.8238 24.1249C66.5968 25.1901 70.4264 30.7528 69.3611 36.5258C68.2959 42.2987 62.7332 46.1283 56.9603 45.0631Z' fill='%23393939'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  width: 87px;
  height: 86px;
  top: -40px;
  left: -24px;
  transform: rotate(10.455deg);
}
@media (max-width: 1366px) {
  .section__404-block h1:before {
    width: 50%;
    height: 50%;
    right: 0;
  }
}

.text__404 {
  color: #393939;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
@media (max-width: 1366px) {
  .text__404 {
    font-size: 24px;
  }
}

.section__contacts-block {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 60px;
  padding-top: 40px;
}
@media (max-width: 768px) {
  .section__contacts-block {
    padding-left: 0;
    padding-right: 0;
  }
}

.section__contacts-items {
  margin-bottom: 120px;
}
@media (max-width: 768px) {
  .section__contacts-items {
    margin-bottom: 40px;
  }
}

.section__contacts-item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .section__contacts-item {
    flex-direction: column;
    text-align: center;
    margin-bottom: 24px;
  }
}

.section__contacts-item__text {
  color: #393939;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-right: 24px;
  flex: 0 1 300px;
}
@media (max-width: 768px) {
  .section__contacts-item__text {
    flex: 0 0 auto;
    margin-right: 0;
  }
}
@media (max-width: 450px) {
  .section__contacts-item__text {
    font-size: 24px;
  }
}

.section__contacts-item__info {
  flex: 0 0 300px;
}
@media (max-width: 768px) {
  .section__contacts-item__info {
    flex: 0 0 auto;
  }
}
.section__contacts-item__info a {
  color: #000;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 450px) {
  .section__contacts-item__info a {
    font-size: 24px;
  }
}

.section__contacts-img img {
  width: 100%;
}

.section__mixology-block {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
}
@media (max-width: 1366px) {
  .section__mixology-block {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.section__mixology-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 64px;
}
@media (max-width: 768px) {
  .section__mixology-item {
    margin-bottom: 40px;
  }
}
.section__mixology-item:nth-child(even) .section__mixology-item__col2 {
  order: -1;
  margin-right: 20px;
}
@media (max-width: 1024px) {
  .section__mixology-item:nth-child(even) .section__mixology-item__col2 {
    order: unset;
    margin-right: unset;
  }
}
.section__mixology-item:nth-child(even) .section__mixology-item__col1 {
  margin-right: 0;
}
.section__mixology-item:nth-child(even) .section__mixology-item__btn {
  text-align: right;
}
@media (max-width: 1024px) {
  .section__mixology-item:nth-child(even) .section__mixology-item__btn {
    text-align: center;
  }
}

.section__mixology-item__col1 {
  flex: 0 0 380px;
  max-width: 380px;
  margin-right: 20px;
}
@media (max-width: 1024px) {
  .section__mixology-item__col1 {
    flex: 0 0 35%;
    max-width: 35%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.section__mixology-item__col2 {
  flex: 0 0 calc(100% - 400px);
  max-width: calc(100% - 400px);
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .section__mixology-item__col2 {
    flex: 0 0 calc(65% - 20px);
    max-width: calc(65% - 20px);
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.section__mixology-item__img {
  position: relative;
  z-index: 10;
}
@media (max-width: 1024px) {
  .section__mixology-item__img {
    margin-bottom: 30px;
  }
}
@media (max-width: 1024px) {
  .section__mixology-item__img img {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.section__mixology-item__title {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  color: #393939;
}
@media (max-width: 1024px) {
  .section__mixology-item__title {
    text-align: center;
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .section__mixology-item__title {
    font-size: 32px;
  }
}
@media (max-width: 576px) {
  .section__mixology-item__title {
    font-size: 28px;
    margin-bottom: 12px;
  }
}
@media (max-width: 450px) {
  .section__mixology-item__title {
    font-size: 24px;
  }
}

.section__mixology-item__recipe {
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}
@media (max-width: 1024px) {
  .section__mixology-item__recipe {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 576px) {
  .section__mixology-item__recipe {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.section__mixology-item__recipe .recipe__bg {
  position: absolute;
  content: "";
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media (max-width: 1024px) {
  .section__mixology-item__recipe .recipe__bg img {
    max-width: 50%;
    height: auto;
  }
}
@media (max-width: 576px) {
  .section__mixology-item__recipe .recipe__bg {
    display: none;
  }
}
.section__mixology-item__recipe ul {
  padding-left: 20px;
  margin: 0;
}
.section__mixology-item__recipe ul li {
  color: #262526;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
}

.section__mixology-item__btn {
  margin-bottom: 55px;
}
@media (max-width: 1024px) {
  .section__mixology-item__btn {
    margin-bottom: 12px;
    text-align: center;
  }
}

.section__contacts-btn {
  text-align: center;
  margin-bottom: 84px;
  display: flex;
  justify-content: center;
}

.btn__yellow-video {
  display: inline-flex;
  padding: 8px;
  padding-right: 52px;
  align-items: center;
  background: #ECCC61;
  transition: 0.3s ease-in-out;
  position: relative;
}
.btn__yellow-video:hover {
  background: #C46C29;
}
.btn__yellow-video:after {
  position: absolute;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='8' viewBox='0 0 25 8' fill='none'%3E%3Cpath d='M24.3536 4.35355C24.5488 4.15829 24.5488 3.84171 24.3536 3.64645L21.1716 0.464466C20.9763 0.269204 20.6597 0.269204 20.4645 0.464466C20.2692 0.659728 20.2692 0.976311 20.4645 1.17157L23.2929 4L20.4645 6.82843C20.2692 7.02369 20.2692 7.34027 20.4645 7.53553C20.6597 7.7308 20.9763 7.7308 21.1716 7.53553L24.3536 4.35355ZM0 4.5H24V3.5H0V4.5Z' fill='%23393939'/%3E%3C/svg%3E");
  width: 25px;
  height: 8px;
  right: 10px;
}

.btn__yellow {
  display: inline-flex;
  padding: 8px;
  align-items: center;
  background: #ECCC61;
  transition: 0.3s ease-in-out;
}
.btn__yellow:hover {
  background: #C46C29;
}

.btn__arrow {
  color: #262526;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-right: 71px;
}
.btn__arrow:hover:after {
  right: -33px;
}
.btn__arrow:after {
  position: absolute;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='58' height='8' viewBox='0 0 58 8' fill='none'%3E%3Cpath d='M57.3536 4.35356C57.5488 4.1583 57.5488 3.84171 57.3536 3.64645L54.1716 0.464471C53.9763 0.269209 53.6597 0.269209 53.4645 0.464471C53.2692 0.659733 53.2692 0.976315 53.4645 1.17158L56.2929 4L53.4645 6.82843C53.2692 7.02369 53.2692 7.34028 53.4645 7.53554C53.6597 7.7308 53.9763 7.7308 54.1716 7.53554L57.3536 4.35356ZM-4.37114e-08 4.5L57 4.5L57 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z' fill='%23262526'/%3E%3C/svg%3E");
  width: 58px;
  height: 8px;
  right: 0;
  transition: 0.3s ease-in-out;
}

.btn__arrow_hero {
  margin-top: 66px;
  margin-bottom: 66px;
  margin-left: 80px;
}
@media (max-width: 768px) {
  .btn__arrow_hero {
    margin-left: unset;
  }
}

.main__posts-more {
  text-align: center;
}

.btn__more {
  display: inline-flex;
  padding-bottom: 57px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.btn__more:hover:after {
  top: 40px;
}
.btn__more:after {
  position: absolute;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='25' viewBox='0 0 8 25' fill='none'%3E%3Cpath d='M3.64645 24.3536C3.84171 24.5488 4.15829 24.5488 4.35355 24.3536L7.53553 21.1716C7.7308 20.9763 7.7308 20.6597 7.53553 20.4645C7.34027 20.2692 7.02369 20.2692 6.82843 20.4645L4 23.2929L1.17157 20.4645C0.976311 20.2692 0.659728 20.2692 0.464466 20.4645C0.269204 20.6597 0.269204 20.9763 0.464466 21.1716L3.64645 24.3536ZM3.5 0L3.5 24H4.5L4.5 0L3.5 0Z' fill='black'/%3E%3C/svg%3E");
  width: 8px;
  height: 25px;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;
}

.footer__block {
  padding-top: 16px;
  padding-bottom: 10px;
  border-top: 1px solid #8B8B8B;
  border-bottom: 1px solid #8B8B8B;
  margin-bottom: 30px;
}

.footer__block-items {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .footer__block-items {
    display: none;
  }
}

.footer__block-slider {
  display: none;
}
@media (max-width: 768px) {
  .footer__block-slider {
    display: block;
  }
}

.footer__block-item {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.footer__block-item a {
  display: inline-flex;
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
}
@media (max-width: 768px) {
  .footer__block-item a {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.footer__block-item a:hover img:first-child {
  display: none;
}
.footer__block-item a:hover img:last-child {
  display: block;
}
.footer__block-item a img {
  transition: 0.3s ease-in-out;
}
.footer__block-item a img:first-child {
  visibility: visible;
}
.footer__block-item a img:last-child {
  display: none;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFE8C8;
  padding: 6px;
  display: flex;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 70%;
}
@media (max-width: 576px) {
  .modal-content {
    padding: 0;
  }
}
.modal-content iframe {
  width: 100%;
  height: auto;
  max-width: 100%;
}
.modal-content::before {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}

.close-modal-button {
  position: absolute;
  top: -14px;
  right: -14px;
  cursor: pointer;
}

.vibrate-1 {
  -webkit-animation: vibrate-1 3s linear infinite both;
  animation: vibrate-1 3s linear infinite both;
}

@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
.pulsate-fwd {
  -webkit-animation: pulsate-fwd 5s ease-in-out infinite both;
  animation: pulsate-fwd 5s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.rotate-scale-up {
  -webkit-animation: rotate-scale-up 8s linear infinite both;
  animation: rotate-scale-up 8s linear infinite both;
}

@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(1.2) rotateZ(180deg);
    transform: scale(1.2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(1.2) rotateZ(180deg);
    transform: scale(1.2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
.wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 8s linear infinite both;
  animation: wobble-hor-bottom 8s linear infinite both;
}

@-webkit-keyframes wobble-hor-bottom {
  0%, 100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%, 100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
.section__product .container {
  min-height: 80vh;
}

.section__product-block {
  padding-top: 48px;
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 1366px) {
  .section__product-block {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 1024px) {
  .section__product-block {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
}

.s__product-img {
  margin-bottom: 75px;
}
@media (max-width: 768px) {
  .s__product-img {
    margin-bottom: 36px;
  }
}
.s__product-img img {
  width: 100%;
}

.s__product-title {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .s__product-title {
    justify-content: center;
  }
}
.s__product-title span {
  color: #393939;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.s__product-title span:first-child {
  font-size: 72px;
  margin-right: 16px;
}
@media (max-width: 1024px) {
  .s__product-title span:first-child {
    font-size: 50px;
  }
}
@media (max-width: 576px) {
  .s__product-title span:first-child {
    font-size: 36px;
  }
}
.s__product-title span:last-child {
  font-size: 48px;
}
@media (max-width: 1024px) {
  .s__product-title span:last-child {
    font-size: 36px;
  }
}
@media (max-width: 576px) {
  .s__product-title span:last-child {
    font-size: 24px;
  }
}

.s__product-info {
  display: flex;
}
@media (max-width: 768px) {
  .s__product-info {
    flex-wrap: wrap;
  }
}

.s__product-description {
  margin-right: 20px;
  flex: 0 0 calc(100% - 300px);
}
@media (max-width: 1024px) {
  .s__product-description {
    flex: 0 1 auto;
    margin-right: 0;
  }
}
.s__product-description p {
  font-family: "Montserrat", sans-serif;
  color: #393939;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 768px) {
  .s__product-description p {
    text-align: center;
    margin-bottom: 24px;
  }
}

.s__product-images__block {
  flex: 0 0 280px;
  width: 280px;
}
@media (max-width: 1024px) {
  .s__product-images__block {
    margin-left: 20px;
    flex: 1 0 280px;
  }
}
@media (max-width: 768px) {
  .s__product-images__block {
    margin-left: auto;
    margin-right: auto;
    flex: 0 0 280px;
    text-align: center;
    order: -1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 24px;
  }
}
@media (max-width: 450px) {
  .s__product-images__block {
    flex: 1 1 auto;
  }
}

.s__product-image {
  display: none;
}
.s__product-image img {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.s__product-image.active {
  display: block;
}

.s__product-image__navs {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.s__product-image__nav {
  color: #C7C7C7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.s__product-image__nav:hover {
  color: rgba(57, 57, 57, 0.75);
}
.s__product-image__nav.active {
  color: #393939;
}

.productSwiper {
  width: 680px;
  max-width: 100%;
  height: 100%;
  margin-left: 0;
  margin-bottom: 48px;
}
.productSwiper .swiper-pagination {
  position: relative;
  display: flex;
  bottom: 0;
  justify-content: center;
  z-index: 50;
  max-width: 80%;
  margin: 16px auto;
}
.productSwiper .swiper-pagination-bullet {
  width: 100%;
  flex-shrink: 10;
  border-radius: 0;
  height: 4px;
  position: relative;
  overflow: hidden;
  background: #ECCC61;
  opacity: 1;
  max-width: 40px;
}
.productSwiper .swiper-pagination-bullet-active {
  background: #C46C29;
}
.productSwiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.productSwiper .sl__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
}
.productSwiper .sl__info-col1 {
  flex: 0 0 50px;
  margin-right: 20px;
}
.productSwiper .sl__info-col2 {
  flex: 1 0 auto;
  width: calc(100% - 70px);
}
.productSwiper .sl__info-text {
  color: #393939;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 6px;
}
.productSwiper .sl__info-desc {
  color: #393939;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#__bs_notify__ {
  position: fixed;
  top: calc(100% - 50px) !important;
  border-radius: 0 !important;
}