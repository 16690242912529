#__bs_notify__ {
  position: fixed;
  top: calc(100% - 50px) !important;
  border-radius: 0 !important;
}



header {

}

.get__header-block {

}

.get__header {

}

