@import '_colors.scss';

html, body, html a {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  color: #000000;
}

body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background: linear-gradient(180deg, #EBE9E7 0%, #FFE8C8 100%);

  main {
    flex-grow: 2;
  }
}

body * {
  outline: #0000 none;
  font-family: 'Montserrat Alternates', sans-serif;
}

body.menu__open, html.menu__open {
  overflow-y: hidden;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(49, 51, 57);
  border-radius: 0px;
  background-color: #FFF;
}

body::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  background-color: #FFF;
  border-radius: 0px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgb(49, 51, 57);
  background-color: rgb(49, 51, 57);
}

.nav__html {
  ol {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 40px;
    }
  }
}

section {

}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 182px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: $dark;
  @include lg {

  }
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 124px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: $dark;
  line-height: 1;
  @include lg {
    font-size: calc(124px - 2vmax);
  }
  @include md {
    font-size: calc(124px - 3vmax);
  }
  @include sm {
    font-size: 72px;
    text-align: center;
  }
  @include z {
    font-size: 60px;
  }

}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: $dark;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $dark;
}


a {
  display: inline-block;
  text-decoration: none;
}
